<template>
  <div class="assistant">
    <img src="../../assets/product/assistant/1.png" class="headerimg" />

    <div class="assistantlist">
      <div class="assistantcard">
        <div class="cardtitle">模板消息系统</div>
        <div class="titletext">
          <div>基于微信公众号模板消息的推送系统</div>
          <div>每日下发模板消息超过10万条</div>
        </div>
        <div class="cardinfo">
          <ul>
            <li>不占用推送数量，便于服务号向粉丝发送重要通知</li>
            <li class="line">直接在微信聊天列表呈现，转化率大大超过推送</li>
            <li>支持按照用户标签下发，实现精细化运营</li>
          </ul>
        </div>
      </div>
      <img
        src="../../assets/product/assistant/2.png"
        alt=""
        class="assistantimg"
      />
    </div>

    <div class="assistantlist">
      <img
        src="../../assets/product/assistant/3.png"
        alt=""
        class="assistantimg"
      />
      <div class="assistantcard">
        <div class="cardtitle">渠道码</div>
        <div class="titletext">
          <div>生成不同公众号二维码，监控关注情况</div>
        </div>
        <div class="cardinfo">
          <ul>
            <li>统计各个渠道涨粉情况，精准掌握粉丝来源</li>
            <li class="line">
              支持根据渠道对未关注、已关注粉丝做不同回复，转化率更高
            </li>
            <li>针对推广员个体分发独有二维码，轻松统计推广业绩</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="information contertop">
      <div class="title">强大的研发实力，专业的运营策划团队支持</div>
      <div class="title2">
        来自互联网优秀的技术研发团队，打造安全稳定可用的系统
      </div>

      <div class="informationcard">
        <div class="informationlist">
          <div>
            <div class="cardtitle">99.9%</div>
            <div class="cardinfo">系统稳定性</div>
          </div>
          <div class="cardcenter">
            <div class="cardtitle">10000张</div>
            <div class="cardinfo">每秒生成海报数</div>
          </div>
          <div>
            <div class="cardtitle">200毫秒</div>
            <div class="cardinfo">服务平均响应</div>
          </div>
        </div>

        <div class="informationinfo">
          <div>
            <p class="infotitle">安全稳定</p>
            <p class="infotext">
              多年技术积累，混合云部署，云防火墙，服务治理，弹性伸缩能力，全方位监控，为商户的业务保驾护航。
            </p>
          </div>
          <div class="line">
            <p class="infotitle">极致体验</p>
            <p class="infotext">
              从运营角度实战出发，研发产品，每个功能对应完善的解决方案，应用实战场景，为粉丝提供更好的用户体验。
            </p>
          </div>
          <div class="line">
            <p class="infotitle">高效服务</p>
            <p class="infotext">7*24小时答疑解惑专业指导，打造专业服务团队。</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.title2 {
  font-size: 14px !important;
}

.informationcard {
  width: 70%;
  height: 612px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  margin: auto;
  margin-top: 60px;
  box-sizing: border-box;
  padding: 60px 122px;
  .informationinfo {
    margin-top: 77px;
    .infotitle {
      font-size: 24px;
      font-weight: 500;
      color: #00c05d;
      line-height: 36px;
    }
    .infotext {
      font-size: 14px;
      font-weight: 500;
      color: #616b80;
      line-height: 20px;
      margin-top: 20px;
    }
    .line {
      margin-top: 50px;
    }
  }
  .informationlist {
    display: flex;
    font-weight: 500;
    color: #333333;
    justify-content: space-between;
    text-align: center;
    .cardcenter {
      border-left: 1px solid #2bcb72;
      border-right: 1px solid #2bcb72;
      padding-left: 109px;
      padding-right: 109px;
    }
    .cardtitle {
      font-size: 40px;
      line-height: 60px;
    }
    .cardinfo {
      font-size: 24px;
      line-height: 36px;
    }
  }
}
.assistantlist {
  display: flex;
  width: 70%;
  margin: auto;
  margin-top: 136px;
  align-items: center;
  justify-content: space-between;
  .assistantcard {
    .cardtitle {
      font-size: 36px;
      font-weight: 500;
      color: #00c05d;
      line-height: 50px;
    }

    .titletext {
      margin-top: 60px;
      margin-bottom: 40px;
      font-size: 24px;
      font-weight: 500;
      color: #333333;
      line-height: 33px;
    }

    .cardinfo {
      font-size: 14px;
      font-weight: 500;
      color: #666666;
      line-height: 20px;
      ul {
        list-style: disc;
        padding-left: 12px;
        margin: 0;
        text-align: left;
        .line {
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .assistantimg {
    width: 700px;
  }
}
</style>